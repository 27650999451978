<template>
	<div class="page container">
		<!-- <el-pagination background layout="prev, pager, next" 
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			@prev-click="handlePrevClick"
			@next-click="handleNextClick"
			:total="pagesData.count? pagesData.count : 10">
		</el-pagination> -->
		
		<el-pagination background layout="prev, pager, next"
			:current-page.sync="currentPage"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			@prev-click="handlePrevClick"
			@next-click="handleNextClick"
			:page-size="limit ? limit : 10"			
			:total="pagesData.count ? pagesData.count : 10">
		</el-pagination>
	</div>
</template>

<script>
	export default {
		props: ['pagesData','limit'],
		data() {
			return {
				currentPage: 1
			};
		},
		methods: {
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val){
				console.log(`当前 ${val}`)
				this.$emit('CurrentChange',val)
			},
			handlePrevClick(val) {
				// console.log(`上一页 ${val}`)
				this.$emit('PrevClick',val)
			},
			handleNextClick(val) {
				// console.log(`下一页: ${val}`)
				this.$emit('NextClick',val)
			},
		}
	}
</script>

<style lang="less" scoped>
	.page{
		text-align: center;
		margin-top: 30px;
	}
	/deep/.el-pagination{
		&.is-background{
			.btn-next,.btn-prev{
				background-color: #fff;
			}
			.el-pager{
				li{
					background-color: #fff;
					&:hover{
						color: #d6363b;
					}
					&:not(.disabled).active{
						background-color: #d6363b;
					}
					&:not(.disabled).active:hover{
						color: #fff;
					}
				}
			}
		}
	}
	
</style>
