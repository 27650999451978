<template>
	<div class="pm-main clearfix">
		<h1 id="pro_bid_name">{{commodityInfo.name}}</h1>
		<!-- 商品图片 -->
		<div class="pm-main-l">
			<div id="showbox">
				<div class="main-img">
					<div class="lot-video J-lot-video"></div>
					<div class="lot-img">
						<span id="moveposition"></span>
						<img :src="bannerImg" class="showimg">
					</div>
				</div>
				<p id="bigpicshow">
					<img src="../../assets/img/home/banner.jpeg" style="">
				</p>
			</div>
			<!--展示图片盒子-->
			<div id="showsum">
				<p>
					<span class="dis_block_child smallimg" :class="current == item.sort?'current': '' "
						v-for="(item,index) in introduceImg" :key="index" @click="onItemImg(item)">
						<img :src="item.path">
					</span>
				</p>
			</div>
			<!-- <div class="follow_count">加入关注</div>
			<div class="look_count"><span>6621</span>次围观</div>
			<div class="join_count"><span></span>人报名</div> -->
		</div>
		<!-- 商品信息 -->
		<div class="pm-main-l auction-interaction profession-main">
			<!-- 即将开始 -->
			<div class="free_bid action_bid_aera" v-if="commodityInfo.status ==1 ">
				<ul class="pm-bid-eyebrow">
					<li class="wait-title"><span class="bd_state begin">即将开始</span>拍卖会正在进行中，请尽快报名</li>
					<li id="sf-price">
						<span class="title">起拍价</span>
						<span class="pm-current-price J_Price" id="_curr_price">
							<em style="color:#0ABC9B;">{{commodityInfo.startPrice}}</em>
						</span>
						<em class="rmb-unit" style="color:#0ABC9B;">元</em>
					</li>
				</ul>
				<div class="pm-bid-eye">
					<span class="pay-bzj-button" v-if="isBond == 0" @click="enroll">立即报名</span>
					<span class="pay-bzj-button" v-if="isBond == 1" @click="participateBidding">参与竞价</span>
				</div>
			</div>

			<!-- 正在进行-->
			<div class="bzj_state underway action_bid_aera" v-else-if="commodityInfo.status ==2">
				<ul class="pm-bid-eyebrow">
					<li class="J_PItem" id="sf-countdown">
						<span class="bd_state now">{{commodityInfo.status | goodsStatu}}</span>
						<span class="title J_TimeTitle">倒计时</span>
						<span class="countdown J_TimeLeft">
							<span class="day">{{dateObj.day}}</span><em>天</em>
							<span class="hour">{{dateObj.hr}}</span><em>时</em>
							<span class="minute">{{dateObj.min}}</span><em>分</em>
							<span class="second">{{dateObj.sec}}</span><em>秒</em>
						</span>
						<span id="J_Delay" class="pm-delay">
							<em class="delayCnt">0</em>次延时
						</span>
						<em id="zn-ziy">限时竞价阶段</em>
					</li>
					<li id="sf-price">
						<span class="title">当前价</span>
						<span class="pm-current-price J_Price" id="_curr_price">
							<em>{{nowPrice}}</em>
						</span>
						<em class="rmb-red">元</em>
						<div class="pm-statusbox">
							<div class="pm-status" style="display: none;"></div>
						</div>
					</li>
				</ul>
				<div class="pm-bid-eye">
					<span class="pay-bzj-button" v-if="isBond == 0" @click="enroll">立即报名</span>
					<span class="pay-bzj-button" v-if="isBond == 1" @click="participateBidding">参与竞价</span>
				</div>
			</div>
			<!-- 已结束 已流拍 已撤拍 -->
			<div class="bzj_state action_bid_aera finished" v-else>
				<ul class="pm-bid-eyebrow end_time">
					<li class="J_PItem sf-countdown">
						<span class="bd_state finish">{{commodityInfo.status | goodsStatu}}</span>
						<span class="time">{{commodityInfo.actualEndTime}}</span>
						<span class="fz16 ml5">结束</span>
					</li>
				</ul>
				<!-- 已成交 -->
				<div class="pm-bid tex-center" v-if="commodityInfo.status == 3 ">
					<p class="currentrate">成交价：
						<span class="colorred">{{commodityInfo.nowPrice}}</span>
						<em class="colorred">元</em>
					</p>
					<p class="pp_endstate">本标的已成交</p>
					<img src="../../assets/img/details/successbdiocn.png" class="successbdiocn">
				</div>
				<!-- 已流拍 -->
				<div class="pm-bid tex-center mt50" v-if="commodityInfo.status == 4 ">
					<p class="pp_endstate type4">本标的已流拍，未达到保留价！</p>
				</div>
				<!-- 已撤拍 -->
				<div class="pm-bid tex-center mt50" v-if="commodityInfo.status == 5 ">
					<p class="pp_endstate">本标的已撤拍</p>
				</div>
			</div>

			<!-- <div class="servicefeetips">
				<p>成交后，需向拍卖企业另付<em>10%</em>拍卖佣金
					<span></span>
				</p>
			</div> -->
			<div class="auctionnews dis_block_parent pt10">
				<p class="p_td3 dis_block_child mr0 dis_first reduction">
					<span id="rate_reduction" class="typeTips">
						<span></span>
						<em class="detail-tip-icon detail-tip-icon-top"></em>
					</span>
					拍卖形式：
					<span class="color-red">
						<span class="J_Price" id="ratereduction"></span>
						<em class="ratered-explain">?</em>
					</span>
				</p>
				<p class="p_td1 dis_block_child dis_first">
					<span id="start_price" class="typeTips">
						<!-- <span>玖拾壹万柒仟叁佰元整</span> -->
						<em class="detail-tip-icon detail-tip-icon-top"></em>
					</span> 起拍价：
					<span class="J_Price" id="startPrice">{{commodityInfo.startPrice}}&nbsp;元</span>
				</p>
				<p class="p_td2 dis_block_child dis_two">
					<span id="deposite_price" class="typeTips">
						<!-- <span>壹拾万元整</span> -->
						<em class="detail-tip-icon detail-tip-icon-top"></em>
					</span> 保证金：
					<span class="J_Price" id="cashDeposit">{{commodityInfo.earnestMoney}}</span>
					<em class="m-i">元</em>
				</p>
				<p class="p_td3 dis_block_child mr0 dis_two increase">
					<span id="rate_price" class="typeTips">
						<!-- <span>壹仟元整</span> -->
						<em class="detail-tip-icon detail-tip-icon-top"></em>
					</span> 加价幅度：
					<span class="color-red">
						<span class="J_Price" id="rateLadder">{{commodityInfo.markupPrice}}</span>
						<em class="m-i">元</em>
					</span>
				</p>
				<p class="p_td1 dis_block_child dis_first">
					评估价：
					<!-- <span class="J_Price" id="assessPrice">无</span> -->
					<em class="m-i">{{commodityInfo.appraisalPrice}}</em>
				</p>
				<p class="p_td2 dis_block_child dis_two">
					<!-- <span class="retain_typeTips typeTips">
						拍卖企业设定的标的最低成交价格，最终的价格低于该价格即流拍<br>
						<a href="#" target="_blank">
							<span>详细。</span>
						</a>
						<em class="detail-tip-icon detail-tip-icon-top"></em>
					</span> -->
					保留价：
					<em id="retain">无</em>
				</p>
				<p class="p_td3 dis_block_child mr0 dis_two increase increase1">
					<!-- <span id="first_tips" class="typeTips">
						优先购买权又称先买权，是指法律赋予特定对象（特定人） 依照法律规定或合同约定，在出卖标的于第三人时，享有的在同等条件下优先<br>
						<a href="#" target="_blank">
							<span>详细。</span>
						</a>
						<em class="detail-tip-icon detail-tip-icon-top"></em>
					</span> -->
					优先购买权人：
					<em id="prior_span">无</em>
				</p>
			</div>
			<div class="auctionnews dis_block_parent w220">
				<p class="p_td2 dis_block_child mr0 dis_three reduction">
					降价周期：
					<span class="J_Price" id="reductionDeposit"></span>
				</p>
				<p class="p_td2 dis_block_child mr0 dis_three reduction">
					降价幅度：
					<span class="J_Price" id="reductionPrice" style="color: #d6363b;"></span>
				</p>
			</div>
			<div class="auctionnews dis_block_parent w220">
				<p class="p_td1 dis_block_child dis_three mr0 increase">
					自由竞价时间：<span id="bidCycle">{{commodityInfo.dayLimit}}天0小时0分0秒</span>
				</p>
				<p class="p_td2 dis_block_child mr0 dis_three increase increase1">
					<span class="limited typeTips ">
						竞拍最后<em>{{commodityInfo.timeLimit}}分0秒</em>内，若有用户出价，即进入限时竞价时间。<br>
					</span>
					限时竞价时间：<span id="delayTimes">{{commodityInfo.timeLimit}}分0秒</span>
				</p>
			</div>
			<div class="last-box">
				<div class="location">
					所在地：<span>{{commodityInfo.province}}{{commodityInfo.city}}{{commodityInfo.county}}</span></div>
				<div class="aucNo" v-if="commodityInfo.aucNo">竞拍号：<span>{{commodityInfo.aucNo}}</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getBiddingInfo
	} from '@/axios/api.js'
	import auctionTab from '@/components/detail/auctioneerspeak.vue'
	import detailMain from '@/components/detail/detailMain.vue'
	export default {
		props: ['commodityInfo', 'introduceImg', "isBond"], //,'dateObj'
		data() {
			return {
				auction: '1',
				activeName: '1',
				biddingRecord: "竞价记录",
				tableData: [{
					status: '领先',
					num: 'X1342',
					price: '¥926,300',
					time: '2021年08月25日 12:43:13'
				}, {
					status: '出局',
					num: 'F5439',
					price: '¥925,300',
					time: '2021年08月25日 11:43:23'
				}, {
					status: '出局',
					num: 'X1342',
					price: '¥924,300',
					time: '2021年08月25日 10:43:43'
				}, ],
				bannerImg: '',
				current: 1, //当前图片
				nowPrice: '', //当前价格实时更新
				status: '', //商品状态
				timeend: '', //倒计时实时更新
				dateObj: {
					day: 0,
					hr: 0,
					min: 0,
					sec: 0,
				},
				timer: null
			};
		},
		components: {
			auctionTab,
			detailMain
		},
		created() {
			this.getBiddingInfo()
			this.biddingRecord = `竞价记录(${this.tableData.length})`
		},
		mounted() {
			setTimeout(() => {
				const {
					status
				} = this.commodityInfo
				console.log(status)
				if (status == 2) {
					// this.timer = setInterval(() => {
					// 	this.getBiddingInfo()
					// }, 1000)
					// this.$once('hook:beforeDestroy', () => {
					// 	clearInterval(this.timer)
					// })
					
					const timer = setInterval(() => { //绑定在 const 普通变量身上，不要绑定在 this上
						this.getBiddingInfo();
						console.log("继续调用");
					}, 1000)
					this.$once('hook:beforeDestroy', () => { //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
						clearInterval(timer);
					})
					
				}
			}, 2000)
		},


		// mounted(){
		// 	const { status } = this.commodityInfo
		// 	if(status == 2){
		// 		this.timer = setInterval(() => {
		// 			this.getBiddingInfo()
		// 		},1000)
		// 		this.$once('hook:beforeDestroy',()=>{
		// 			clearInterval(this.timer)
		// 		})
		// 	}
		// },
		methods: {
			//头部右侧拍卖信息 
			handleAuction(tab, event) {
				console.log(tab, event);
			},
			// 详情tag
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// 图片切换
			onItemImg(data) {
				this.bannerImg = data.path
				this.current = data.sort
			},
			// 立即报名
			enroll() {
				this.$emit('onEnroll')
			},
			// 参与竞价
			participateBidding() {
				this.$emit('handleBidding')
			},
			// 标的信息
			getBiddingInfo() {
				let that = this
				let data = {
					bid: this.$route.query.id
				}
				getBiddingInfo(data).then(function(res) {
						console.log('定时:', res.data)
						let data = res.data
						if (data.code == 0) {
							const {
								nowPrice,
								status,
								actualEndTime
							} = data.result
							that.nowPrice = nowPrice
							that.status = status
							that.timeend = actualEndTime
							that.countdown()
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 倒计时
			countdown: function() {
				//定义this指向
				const that = this;
				//获取结束时间
				let thisTime = new Date(that.timeend.replace(/-/g, '/'));
				const end = thisTime.getTime();
				//设置开始时间
				const now = new Date().getTime();
				// console.log('countdown',now,end)
				// 做判断当倒计时结束时都为0
				if (now >= end) {
					that.dateObj.day = 0;
					that.dateObj.hr = 0;
					that.dateObj.min = 0;
					that.dateObj.sec = 0;
					return;
				}
				// 用结束时间减去当前时间获得倒计时时间戳
				const msec = end - now;
				let day = parseInt(msec / 1000 / 60 / 60 / 24); //算出天数
				let hr = parseInt((msec / 1000 / 60 / 60) % 24); //算出小时数
				let min = parseInt((msec / 1000 / 60) % 60); //算出分钟数
				let sec = parseInt((msec / 1000) % 60); //算出秒数
				//给数据赋值
				that.dateObj.day = day;
				that.dateObj.hr = hr > 9 ? hr : "0" + hr;
				that.dateObj.min = min > 9 ? min : "0" + min;
				that.dateObj.sec = sec > 9 ? sec : "0" + sec;

				//使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
				setTimeout(function() {
					that.countdown();
				}, 1000);
			},
		},
		beforeDestroy() {
			clearInterval(this.timer)
			this.timer = null
		},
		watch: {
			// 处理父组件传过来的数据取第一个数组图片
			introduceImg(val) {
				if (val.length != 0) {
					this.bannerImg = val[0].path
				}
			}
		},
	};
</script>

<style lang="less" scoped>
	.wait-title {
		background: #fff;
	}

	.pm-main {
		width: 875px;
		padding: 20px 0 30px 20px;
		background: #fff;

		h1 {
			font-size: 18px;
			line-height: 24px;
			color: #333;
			margin: 0 0 10px;
		}

		.pm-main-l {
			width: 420px;
			float: left;
			display: inline;
		}

		#showbox {
			width: 420px;
			height: 295px;
			position: relative;
			z-index: 1000;
			margin-top: 18px;

			.main-img {
				height: 280px;
				width: 420px;
				position: relative;
			}
		}

		#moveposition {
			position: absolute;
			left: 220px;
			top: 0;
			display: none;
			z-index: 5;
			width: 200px;
			height: 140px;
			background: url(../../assets/img/details/whitebg.png);
			// left: 128px; 
			// top: 140px;
		}

		.showimg {
			display: block;
			width: 420px;
			height: 280px;
			border-radius: 2px;
		}

		#bigpicshow {
			position: absolute;
			overflow: hidden;
			top: 0;
			left: 430px;
			display: none;
			width: 420px;
			height: 294px;

			// left: -256px; top: -280px;
			img {
				max-width: none;
				max-height: none;
				position: relative;
				left: -462px;
				top: 0;
				width: 840px;
				height: 560px;
			}
		}

		#showsum {
			overflow: hidden;
			width: 420px;
			margin-bottom: 30px;
			position: relative;

			p {
				margin-right: -20px;
				height: 48px;
				overflow: hidden;

				span {
					display: inline-block;
					letter-spacing: 0;
					vertical-align: top;
					margin-right: 15px;
					width: 70px;
					height: 46px;
					margin: 0 15px 0 0;
					border: 1px solid #e5e5e5;
					cursor: pointer;
					overflow: hidden;

					&.current {
						border: 2px solid #d6363b;
						width: 66px;
						height: 42px;
						border-radius: 2px;
					}
				}

				img {
					width: 100%;
					height: 48px;

				}
			}
		}

		.follow_count,
		.look_count {
			padding-left: 19px;
			height: 17px;
			margin-right: 18px;
		}

		.follow_count {
			float: left;
			font-size: 12px;
			line-height: 19px;
			cursor: pointer;
			color: #C13A37;
			background: url(../../assets/img/details/follow_checked.png) no-repeat;
		}

		.look_count,
		.join_count {
			span {
				color: #666;
			}
		}

		.look_count {
			font-size: 14px;
			color: #999;
			display: inline-block;
			background: url(../../assets/img/details/look_count.png) left center no-repeat;
			line-height: 17px;
		}

		.join_count {
			font-size: 14px;
			color: #999;
			padding-left: 12px;
			// background: url(/themes/images/join_count.png) left center no-repeat;
			display: none;
		}

		.auction-interaction {
			width: 455px;
			position: relative;

			.countdown,
			.pm-current-price {
				margin-top: 2px;
				vertical-align: middle
			}

			.underway {
				background: #FDF4F5;

				#sf-countdown {
					background: #fff;
					position: relative;
				}
			}

			.title {
				font-size: 12px;
				margin: 0 15px 0 0;
			}

			.J_TimeTitle {
				color: #363636 !important;
				margin-right: 12px !important;
			}

			#zn-ziy {
				// background: url(/themes/images/iocn/limited.png) left 7px center no-repeat #FAD4D8;
				background-size: 18px 18px;
				color: #D2051E;
				font-size: 12px;
				right: -2px;
				top: 15px;
				z-index: 9999;
				width: 111px;
				height: 23px;
				line-height: 25px;
				text-indent: 30px;
				display: none;
			}

			.pm-bid-eye {
				width: 100%;
				background: 0 0;
				margin-top: 18px;
				position: relative;

				.pm-message {
					dt {
						height: 28px;
						line-height: 28px;
					}

					.Regis {
						display: block;
						font-size: 12px;
						margin-top: 8px;
						text-align: center;
						color: #999;
					}
				}

				.pm-bid {
					padding-top: 0;
					color: #666;

					dd,
					dt,
					.pm-price {
						position: relative;
					}

					dd,
					dt {
						float: left;
						width: 265px;
					}

					dt {
						text-align: left;
						margin: 0 20px 0 8px;
						height: 40px;
						width: 36px;
						line-height: 40px;
					}

					dd {
						width: 296px;
					}
				}

				.pm-bid-eyebrow {
					margin-bottom: 0;
				}

			}
		}

		.pm-bid-eyebrow {
			li {
				padding: 11px 0;
				margin-bottom: 10px;
				position: relative;
				line-height: 30px;
				height: 30px;
			}

			.bd_state {
				color: #fff;
				font-size: 12px;
				width: 65px;
				height: 24px;
				border-radius: 100px;
				margin-right: 12px;
				text-align: center;
				line-height: 24px;
				display: inline-block;
			}

			.now {
				background: #C1393C;
			}

			.countdown {
				margin-left: -2px;
				font-size: 22px;
				color: #333;

				em {
					margin-left: 3px;
					color: #000;
					font-size: 12px;
				}
			}

			#sf-price {
				z-index: 9;
				margin-top: 5px;
				margin-bottom: 0;

				.title {
					color: #686868;
					font-size: 12px;
					margin: 0 15px 0 8px;
				}
			}

			.rmb-red {
				color: #d6363b;
				font-size: 14px;
				margin: 0 12px 0 6px;
			}

			em {
				font-size: 28px;
				margin: 0 3px 0 -5px;
				color: #d6363b;
			}

			.pm-delay {
				display: none
			}

			.pm-bid-cnt em,
			.delayCnt {
				margin-left: 3px;
			}
		}

		.pay-bzj-button {
			width: 141px;
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-size: 16px;
			border: 1px solid transparent;
			background: #d6363b;
			color: #fff;
			cursor: pointer;
			border-radius: 4px;
			display: block;
			margin: 0 auto 20px;

			.qq {
				cursor: not-allowed;
				color: rgb(102, 102, 102);
				height: 25px;
				font-size: 12px;
				margin-bottom: 0px;
				background: none;
			}
		}

		.servicefeetips {
			width: 422px;
			margin-left: 19px;
			height: 32px;
			background: rgb(255, 244, 244);
			margin-top: 11px;
			position: relative;

			p {
				font-size: 12px;
				line-height: 32px;
				background: url(../../assets/img/details/servicefeetips.png) no-repeat left 9px center;
				color: #C21F3A;
				padding-left: 28px;
				float: left;
				background-size: 12px 12px;
			}
		}

		.auctionnews {
			font-size: 0px;
			color: #666;
			padding-left: 0;
			margin-left: 18px;
			width: 450px;
			letter-spacing: -3px;

			p {
				width: 210px;
				font-size: 12px;
				color: #999;
				position: relative;
				padding-top: 15px;

				em,
				span {
					color: #333;
				}
			}

			.dis_first {
				width: 154px !important;
			}

			.dis_two {
				width: 141px !important;
			}

			.dis_block_child {
				display: inline-block;
				letter-spacing: 0;
				vertical-align: top;

				.typeTips {
					position: absolute;
					padding: 8px 14px;
					border: 1px solid #DDD;
					line-height: 1.5;
					background-color: #fff;
					box-shadow: 1px 1px 3px #ddd;
					-webkit-box-shadow: 1px 1px 3px #ddd;
					-moz-box-shadow: 1px 1px 3px #DDD;
					z-index: 10000;
					left: 30px;
					width: 100px;
					display: none;
					text-align: center;
				}

				.retain_typeTips {
					width: 183px;
					text-align: left;
					color: rgb(51, 51, 51);
					top: -60px;
					display: none;
				}

				#start_price,
				#deposite_price {
					display: none;
					top: -24px;
				}

				#first_tips {
					color: #333;
					top: -80px;
					width: 300px;
					text-align: left;
					left: 64px;
					color: rgb(51, 51, 51);
					top: -78px;
					display: none;
				}

				.limited {
					color: rgb(51, 51, 51);
					width: 183px;
					text-align: left;
					display: none;
					top: -42px;
				}
			}

			.reduction {
				display: none;
			}
		}

		.last-box {
			display: flex;

			.location,
			.aucNo {
				width: 210px;
				margin-left: 18px;
				margin-top: 14px;
				display: block;
			}

			.aucNo {
				margin-left: 0px;
			}
		}

		.location,
		.restrictions,
		.aucNo {
			display: none;
			color: #999;
			font-size: 12px;

			span {
				color: #333;
			}
		}

		.cut-off {
			cursor: not-allowed;
			color: #666666;
			height: 25px;
			font-size: 12px;
			margin-bottom: 0;
			background: none;
		}

		.Regis {
			display: block;
			font-size: 12px;
			margin-top: 8px;
			text-align: center;
			color: #999;
		}
	}

	.action_bid_aera {
		width: 420px;
		margin-left: 19px;
		padding-bottom: 10px;
		min-height: 202px;
	}

	// 已结束
	.finished {
		background: #F5F5F5;
		position: relative;

		li {
			margin-bottom: 10px;
			line-height: 30px;
			padding-left: 8px;
			height: 30px;
			position: relative;

			.finish {
				background: #AEAEAE;
			}

			.bd_state {
				color: #fff;
				font-size: 12px;
				width: 65px;
				height: 24px;
				border-radius: 100px;
				margin-right: 12px;
				text-align: center;
				line-height: 24px;
				display: inline-block;
			}

			.title {
				font-size: 16px;
				margin: 0 12px 0 4px;
				color: #363636 !important;
			}

		}

		.sf-countdown {
			padding: 11px 0;
			background: #fff;

			.time {
				color: #363636;
				font-size: 16px;

			}
		}

		.sf-price {
			margin-bottom: 0;
			z-index: 9;
			margin-top: 5px;

			.title {
				margin-left: 8px;
				color: #686868;
				font-size: 12px;
				margin: 0 15px 0 0;
			}
		}

		.pm-bid {
			p {
				color: #686868;
			}

			.currentrate {
				font-size: 14px;
				margin-left: 6px;

				span {
					font-size: 26px;
				}

				em {
					font-size: 12px;
					margin-left: 5px;
				}
			}

			.colorred {
				color: #d6363b !important;
			}

			.pp_endstate {
				width: 141px;
				height: 38px;
				border: 1px solid #979797;
				line-height: 38px;
				color: #999 !important;
				margin: 18px auto 0;
				border-radius: 3px;
				text-align: center;
				font-size: 16px;

				&.type4 {
					width: 254px;
				}
			}

			.successbdiocn {
				width: 82px;
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
	}

	// 即将开始
	.free_bid {
		background: #F0FBF9;

		.begin {
			background: #0ab86c;
		}

		.wait-title {
			font-size: 16px;
			color: #333;
		}

		.pm-bid-eyebrow {
			.rmb-unit {
				color: #0ABC9B;
				font-size: 12px;
				margin-left: 2px;
			}
		}

	}
</style>
