<template>
	<div class="details">
		<div class="sifa-content-div container mt20 clearfix">
			<div class="grid-c hall-wrap clearfix">
				<!-- 左边 -->
				<div class="module-sf clearfix">
					<goodsInfo :commodityInfo="commodityInfo" :introduceImg="introduceImg" :dateObj="dateObj"
						:isBond="isBond" @handleBidding="handleBidding" @onEnroll='onEnroll'>
					</goodsInfo>
				</div>
				<!-- 右边 -->
				<div class="pm-bid-right same_bid_right">
					<!-- tab -->
					<el-tabs v-model="auction" type="card" @tab-click="handleAuction">
						<!-- 拍卖师发言 -->
						<el-tab-pane label="拍卖师发言" name="1">
							<auctionTab :auction="auction" :statementCon='statementCon'></auctionTab>
						</el-tab-pane>

						<!-- 拍卖师信息 -->
						<el-tab-pane label="拍卖师信息" name="2">
							<auctionTab :auction="auction" :auctioneerInfo="auctioneerInfo"></auctionTab>
						</el-tab-pane>
					</el-tabs>

					<!-- 竞价记录 -->
					<div class="record">
						<h2 class="state">
							<span>状态</span>
							<span class="bid-number">竞买号</span>
							<span class="offer-price">出价</span>
						</h2>
						<ul class="recordUl recordList" :class="tableData.length == 0?'height0':0 ">
							<li :class="index == 0? 'active0':'active1' " v-for="(item,index) in tableData"
								:key="index">
								<p>
									<span class="record-icon">{{item.status | bidStatu}}</span>
									<span class="nickname ">{{item.aucNo}}</span>
									<span style="width: 36%;" class="">¥{{item.price}}</span>
								</p>
							</li>
						</ul>
						<a v-if="tableData.length != 0" class="see-record" style="">更多</a>
						<p v-if="tableData.length == 0">暂无竞价记录</p>
					</div>
				</div>
			</div>
		</div>

		<!-- Tabs -->
		<div class="tabs container mt15">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<!-- 拍卖公告 -->
				<el-tab-pane label="拍卖公告" name="1">
					<detailMain :videoImg='0' :adjunct='1' :afficheCon="afficheCon"></detailMain>
				</el-tab-pane>

				<!-- 竞买须知 -->
				<el-tab-pane label="竞买须知" name="2">
					<detailMain :videoImg='0' :adjunct='0' :afficheCon="afficheCon"></detailMain>
				</el-tab-pane>

				<!-- 标的介绍 -->
				<el-tab-pane label="标的介绍" name="3">
					<detailMain :videoImg='1' :adjunct='1' :afficheCon="afficheCon"></detailMain>
				</el-tab-pane>

				<!-- 竞价记录 -->
				<el-tab-pane :label="biddingRecord" name="4">
					<div class="detail-main">
						<el-table :data="tableData" :row-class-name="rowClassName" class="record-table">
							<!-- 状态 -->
							<el-table-column prop="status" label="状态">
								<template slot-scope="scope">
									{{scope.row.status | bidStatu}}
								</template>
							</el-table-column>
							<!-- 竞买号 -->
							<el-table-column prop="aucNo" label="竞买号">
							</el-table-column>
							<!-- 价格 -->
							<el-table-column prop="price" label="价格">
							</el-table-column>
							<!-- 时间 -->
							<el-table-column prop="aucTime" label="时间">
								<template slot-scope="scope">
									{{scope.row.aucTime | formatDate}}
								</template>
							</el-table-column>
						</el-table>
						<!-- 分页 -->
						<page v-if="tableData.length > 0" :pagesData='pagesData' @CurrentChange="currentChange"
							@PrevClick='getPrevClick' @NextClick='getNextClick'>
						</page>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

		<!-- 提示弹窗 -->
		<el-dialog title="提示" :visible.sync="dialogVisible" center>
			<span>您尚未实名认证，是否前往认证!</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">否</el-button>
				<el-button type="primary" @click="linkAttestation">是</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import auctionTab from '@/components/detail/auctioneerspeak.vue'
	import detailMain from '@/components/detail/detailMain.vue'
	import goodsInfo from '@/components/detail/goodsInfo.vue'
	import page from '@/components/page.vue'

	import {
		getBiddingInfo,
		getBiddingNotice,
		getBiddingImg,
		getBiddingRecord,
		raiseCard,
		isBond,
		enroll
	} from '../../axios/api.js'
	export default {
		data() {
			return {
				auction: '1',
				activeName: '1',
				biddingRecord: "竞价记录",
				commodityInfo: '',
				tableData: [],
				type: 1, //类型 （1-公告，2-须知 ）,
				afficheCon: '', //公告
				imgType: 2, //类型： 1-缩略图，2-展示图，3-详情图（标的介绍）
				introduceImg: '', //标的介绍
				page: 1, //页码
				limit: 10, //条数
				statementCon: [], //拍卖师发言
				auctioneerInfo: '', //拍卖师信息		
				pagesData: '', //分页		
				timeend: 0, //结束时间 
				dateObj: {
					day: 0,
					hr: 0,
					min: 0,
					sec: 0,
				},
				isBond: 0,
				dialogVisible: false, //是否认证
				status: '', //
				timer: null,
			};
		},
		components: {
			auctionTab,
			detailMain,
			goodsInfo,
			page
		},
		created() {
			this.getBiddingInfo()
			this.getBiddingImg()
			this.getBiddingNotice()
			this.getBiddingRecord()
			this.getIsBond()
		},
		mounted() {
			setTimeout(() => {
				const {
					status
				} = this.commodityInfo
				console.log(status)
				if (status == 2) {
					this.timer = setInterval(() => {
						this.getBiddingRecord()
					}, 1000)
					this.$once('hook:beforeDestroy', () => {
						clearInterval(this.timer)
					})
				}
			}, 2000)
		},
		// mounted(){
		// 	const { status } = this.commodityInfo
		// 	if(status == 2){
		// 		this.timer = setInterval(() => {
		// 			this.getBiddingRecord()
		// 		},1000)
		// 		this.$once('hook:beforeDestroy',()=>{
		// 			clearInterval(this.timer)
		// 		})
		// 	}
		// },
		methods: {
			rowClassName(row) {
				if (row.row.status == 1) {
					return 'colorRed'
				} else {
					return ''
				}
			},
			currentChange(data) {
				this.page = data
				this.getBiddingRecord()
			},
			// 点击上一页
			getPrevClick(data) {
				console.log(data)
				this.page = data
				this.getBiddingRecord()
			},
			// 点击下一页
			getNextClick(data) {
				console.log(data)
				this.page = data
				this.getBiddingRecord()
			},
			//头部右侧拍卖信息 
			handleAuction(tab, event) {
				console.log(tab, event);
			},
			// 详情tag
			handleClick(tab, event) {
				console.log(tab, event);
				this.type = tab.name
				if (tab.name == 1 || tab.name == 2 || tab.name == 3) {
					this.getBiddingNotice()
				}
			},
			// 标的信息
			getBiddingInfo() {
				let that = this
				let data = {
					bid: this.$route.query.id
				}
				getBiddingInfo(data).then(function(res) {
						console.log('getBiddingInfo:', res.data)
						let data = res.data
						if (data.code == 0) {
							that.commodityInfo = data.result
							that.timeend = data.result.actualEndTime
							that.status = data.status
							that.countdown()
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 获取标的公告/须知
			getBiddingNotice() {
				let that = this
				let data = {
					bid: this.$route.query.id,
					type: this.type
				}
				getBiddingNotice(data).then(function(res) {
						console.log('getBiddingNotice:', res.data)
						let data = res.data
						if (data.code == 0) {
							if (data.result) {
								that.afficheCon = data.result.content
							}

						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 获取标的介绍 图片（详情图）
			getBiddingImg() {
				let that = this
				let data = {
					bid: this.$route.query.id,
					type: this.imgType
				}
				getBiddingImg(data).then(function(res) {
						console.log('getBiddingImg:', res.data)
						let data = res.data
						if (data.code == 0) {
							if (data.result) {
								that.introduceImg = data.result
							}

						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 竞价记录
			getBiddingRecord() {
				let that = this
				let data = {
					bid: this.$route.query.id,
					page: this.page,
					limit: this.limit
				}
				getBiddingRecord(data).then(function(res) {
						console.log('getBiddingRecord:', res.data)
						let data = res.data
						if (data.code == 0) {
							if (data.result) {
								that.tableData = data.result
								that.biddingRecord = `竞价记录(${data.count})`
								that.pagesData = data
							}

						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 竞拍资格检查isBond
			getIsBond() {
				let that = this
				let data = {
					bid: this.$route.query.id
				}
				isBond(data).then(function(res) {
						console.log('竞拍资格检查:', res.data)
						let data = res.data
						if (data.code == 200) {
							that.isBond = data.result
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 立即报名
			onEnroll() {
				let that = this
				let data = {
					bid: this.$route.query.id
				}
				enroll(data).then(function(res) {
						console.log('立即报名:', res.data)
						let data = res.data
						if (data.code == 200) {
							that.getIsBond()
						} else if (data.code == 501) {
							that.$message({
								message: data.msg,
								type: 'error'
							})
							// 未实名跳转到实名认证
							that.dialogVisible = true

						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 参与竞价举牌
			handleBidding() {
				let that = this
				this.$confirm('是否参与竞价?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						bid: this.$route.query.id
					}
					raiseCard(data).then(function(res) {
							console.log('raiseCard:', res.data)
							let data = res.data
							if (data.code == 0) {
								that.$message({
									message: '恭喜您，竞价成功',
									type: 'success'
								})
								that.getBiddingRecord()
							} else if (data.code == 501) {
								that.$message({
									message: data.msg,
									type: 'error'
								})
								// 未实名跳转到实名认证
								that.dialogVisible = true
					
							} else {
								that.$message({
									message: data.msg,
									type: 'error'
								})
							}
						})
						.catch(function(err) {
							console.log('err:', err)
						})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			// 倒计时
			countdown: function() {
				//定义this指向
				const that = this;
				//获取结束时间
				let thisTime = new Date(that.timeend.replace(/-/g, '/'));
				const end = thisTime.getTime();
				//设置开始时间
				const now = new Date().getTime();
				// console.log('countdown',now,end)
				// 做判断当倒计时结束时都为0
				if (now >= end) {
					that.dateObj.day = 0;
					that.dateObj.hr = 0;
					that.dateObj.min = 0;
					that.dateObj.sec = 0;
					return;
				}
				// 用结束时间减去当前时间获得倒计时时间戳
				const msec = end - now;
				let day = parseInt(msec / 1000 / 60 / 60 / 24); //算出天数
				let hr = parseInt((msec / 1000 / 60 / 60) % 24); //算出小时数
				let min = parseInt((msec / 1000 / 60) % 60); //算出分钟数
				let sec = parseInt((msec / 1000) % 60); //算出秒数
				//给数据赋值
				that.dateObj.day = day;
				that.dateObj.hr = hr > 9 ? hr : "0" + hr;
				that.dateObj.min = min > 9 ? min : "0" + min;
				that.dateObj.sec = sec > 9 ? sec : "0" + sec;

				//使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
				setTimeout(function() {
					that.countdown();
				}, 1000);
			},
			// 报名或者参与竞价没有认证弹出
			linkAttestation() {
				// 缓存用户是否去实名认证
				this.dialogVisible = false
				// 获取用户属性
				let _type = JSON.parse(sessionStorage.getItem('userInfo')).type
				// let _type = JSON.parse(localStorage.getItem('userInfo')).type
				// console.log(_type)
				this.$router.push({
					path: '/personal',
					query: {
						showInfo: 1
					}
				})
			},
		},

		beforeDestroy() {
			clearInterval(this.timer)
			this.timer = null
		},
	};
</script>

<style lang="less" scoped>
	.hall-wrap {
		overflow: hidden;
		position: relative;

		.module-sf {
			width: 895px;
			margin: 0 auto;
			color: #666;
			position: relative;
			float: left;
		}

		.pm-bid-right {
			float: right;
			width: 290px;
			margin: 0;
			background: #fff;
			overflow: hidden;
			position: absolute;
			top: 0;
			text-align: center;
			right: 0;
			bottom: 0;
		}

		/deep/.el-tabs {
			>.el-tabs__header {
				.el-tabs__nav {
					width: 100%;
					background: #eaeaea;
				}

				.el-tabs__item {
					&:last-child {
						padding-right: 0;
					}

					&:nth-child(2) {
						padding-left: 0;
					}
				}
			}

			.el-tabs__item {
				width: 50%;
				padding: 0;
				height: 40px;
				line-height: 40px;
				font-size: 12px;
				font-weight: normal;

				&.is-active {
					background: #fff;
					height: 42px;
				}
			}
		}
	}

	.tabs {
		background: #fff;
		margin-bottom: 40px;

		.detail-main {
			background: #fff;
			padding-bottom: 52px;
			margin: 0 40px;
			min-height: 580px;

			.pai-title {
				position: relative;
				text-align: center;
				height: 60px;
				padding-top: 15px;
			}

			.pai-remind-tip {
				padding: 0 8px;
				line-height: 24px;
				font-size: 16px;

				p {
					font-size: 14px;
				}
			}

			.record-table {
				width: 800px;
				margin: 25px auto 2px;
				text-align: center;

				tr {

					td,
					th {
						text-align: center;
						border-bottom: solid 1px #e9e9e9;
						vertical-align: middle;
						padding: 15px 0;
						color: #848484;
					}
				}
			}

			/deep/.el-table {
				td,
				th {
					font-size: 12px;
					padding: 10px 0;
					text-align: center;
				}

				.el-table__row {
					&.colorRed {
						color: #d91615;
						font-weight: bold;
					}
				}
			}
		}
	}

	/deep/.el-tabs {
		>.el-tabs__header {
			border-bottom: 1px solid #d6363b;

			.el-tabs__nav {
				border: 0px solid #d6363b;
			}

			.el-tabs__item {
				border-left: 0px solid #d6363b;

				&:nth-child(2) {
					padding-left: 30px;
				}

				&:last-child {
					padding-right: 30px;
				}
			}
		}

		.el-tabs__item {
			padding: 0 30px;
			height: 48px;
			line-height: 48px;

			&:hover {
				color: #d7332d;
			}

			&.is-active {
				height: 50px;
				background: #fff;
				color: #d7332d;
				border-bottom: none;
				border-top: 2px solid #d6363b;
				border-right: 1px solid #d6363b;
				border-left: 1px solid #d6363b;
			}

		}

	}

	.record {
		position: relative;

		.state {
			height: 40px;
			line-height: 40px;
			background: #e5e5e5;
			padding: 0 17px 0 21px;
			text-align: left;
			font-weight: 400;
			font-size: 12px;
			overflow: hidden;
			margin: 0;

			span {
				width: 22%;
				float: left;
				line-height: 40px;
				font-size: 12px;

				&.bid-number {
					width: 31%;
				}

				&.offer-price {
					width: 35%;
				}
			}
		}

		.recordUl {
			text-align: left;
			background: #fff;
			padding: 5px 0 0 8px;
			margin-top: -4px;
			margin: 0;

			li {
				border-bottom: none;
				padding: 3px 0;
				margin: 0 12px;

				&.active0 {
					color: #d6363b !important;
				}

				p {
					height: 20px;
					line-height: 20px;
					margin: 0;
					font-size: 14px;

					span {
						width: 30%;
						display: inline-block;
						vertical-align: middle;

						&.record-icon {
							width: 22%;
						}

						&:last-child {
							width: 36%;
						}
					}
				}
			}
		}

		.recordList {
			height: 76px;
			overflow: hidden;
			position: relative;

			&.height0 {
				height: 0;
			}
		}

		.see-record {
			border: 1px solid #ccd1d9;
			border-radius: 3px;
			font-size: 12px;
			color: #666;
			text-align: center;
			width: 40px;
			height: 20px;
			line-height: 20px;
			margin: 8px auto 0;
			display: inline-block;
			cursor: pointer;
		}

		>p {
			padding-top: 10px;
		}
	}

	// 提示弹窗
	/deep/.el-dialog {
		width: 420px;

		.el-button--primary {
			color: #FFF;
			background-color: #f24b53;
			border-color: #f24b53;
		}

		.el-button--default {

			&:focus,
			&:hover {
				color: #f24b53;
				border-color: #f24b53;
				background-color: #fff;
			}
		}

		.el-button+.el-button {
			margin-left: 20px;
		}
	}
</style>
