<template>
	<div class="auctionTab">
		<!-- 拍卖师发言 -->
		<ul class="auctioneerspeak" v-if="auction == '1'&& statementCon != '' ">
			<li class="lineh22" v-for="item in 5" :key="item">
				<p class="p_name">
					<span class="color-red active">系统消息</span>
					<em></em>
					<span class="color-999">2021年08月30日 &nbsp;14:41:49</span>
				</p>
				<p class="p_content">拍卖师修改标的&lt;150T水泥罐及附属设备一批&gt;起拍价由26000.00改为12000.00加价幅度由500.00改为1000.00 </p>
			</li>
		</ul>
		<div class="auctioneerspeak" v-if="statementCon == '' ">
			<p>暂无拍卖师发言</p>
		</div>
		
		<!-- 拍卖师信息 -->
		<div class="auctioneer dis_block_parent hidden" v-if="auction =='2' && auctioneerInfo != '' ">
			<div class="auctioneerpic">
				<img src="../../assets/img/details/auctioneer.jpeg" class="auctioneer-pic J_auctionPhoto">
			</div>
			<div class="auctioneerintroduce lineh16 f12">
				<p class="auctioneername">李雪芹</p>
				<p class="auctioneername2">
					<span class="color-333">拍卖师证号：</span>
					<span class="color-333">1700441</span>
				</p>
			</div>
		</div>
		<div class="auctioneer" v-if="auctioneerInfo == '' ">
			<p>暂无拍卖师信息</p>
		</div>
		
		<!-- 竞价记录 -->
		<!-- <div class="record">
			<h2 class="state">
				<span>状态</span>
				<span class="bid-number">竞买号</span>
				<span class="offer-price">出价</span>
			</h2>
			<ul class="recordUl recordList" style="overflow: auto;">
				<li class="active0">
					<p>
						<span class="record-icon">领先</span>
						<span class="nickname ">O7617</span>
						<span style="width: 36%;" class="">¥31,000</span>
					</p>
				</li>
				<li class="active1">
					<p>
						<span class="record-icon">出局</span>
						<span class="nickname">W7240</span>
						<span style="width: 36%;" class="">¥30,000</span>
					</p>
				</li>
			</ul>
			<a class="see-record" style="">更多</a>
		</div> -->
	</div>
	
</template>

<script>
	export default{
		props: ['auction','statementCon','auctioneerInfo'],
	}
</script>

<style lang="less" scoped>
	.auctioneerspeak {
	    background: #FFF;
	    padding-left: 12px;
	    padding-bottom: 10px;
	    height: 250px;
	    overflow-y: auto;
		li{
			padding-top: 10px;
			text-align: center;
		}
		.lineh22 {
		    position: relative;
		    float: left;
			line-height: 22px;
		}
		.p_name, .p_tit, .p_content{
		    text-align: left;
		}
		.p_content {
		    border: none;
		    background: #f3f3f3;
		    border-radius: 4px;
		    padding: 6px 17px;
		    display: inline-block;
		    float: left;
		    font-size: 13px;
		    margin-right: 20px;
		    width: 207px;
			color: #333;
		}
		.p_name{
			.color-red{				
				font-size: 13px;
				color: #333;
				&.active{
					color: #d6363b;
				}
			}
			em {
			    background: #b6b6b6;
			    width: 1px;
			    height: 12px;
			    opacity: .4;
			    display: inline-block;
			    margin-left: 5px;
			    vertical-align: text-top;
			}
			.color-999 {
			    opacity: .7;
			    text-align: left;
			    color: #333;
			    float: none;
			    margin: 0 12px 0 5px;
			}
		}
	}	
	.dis_block_parent {
	    font-size: 0;
	    letter-spacing: -3px;
	}
	.auctioneer {
	    padding: 20px 15px 15px;
	    background: #FFF;
	    color: #333;
	    text-align: center;
	    letter-spacing: 0;
	    height: 253px;
		.auctioneerpic {
		    width: 125px;
		    margin: 0 auto 15px;
			height: 150px;
			overflow: hidden;
			img{
				width: 100%;
				height: 150px;
				overflow: hidden;
				cursor: zoom-in;
			}
		}
		.square-list {
		    overflow: hidden;
		    padding-left: 47%;
		    width: 100%;
		    margin-bottom: 18px;
		}
		.auctioneername {
		    padding: 2px 0 6px;
		}
		.auctioneername2 {
		    padding: 0 0 6px;
		}
	}
	
</style>
