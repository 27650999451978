<template>
	<div class="detail-main">
		<!-- <div class="pai-title">
			<div class="pai-title-text"></div>
		</div> -->
		
		<div class="detail-common-text" v-html="afficheCon"></div>
		<!-- 图片、视频 -->
		<div class="video-img" v-if="videoImg == '1'">
			<div class="video_slide">
				<div id="lotVideo">IE8及以下不支持视频播放。</div>
			</div>
			<!-- <div class="sf-pic-slide clearfix">
				<img v-for="(item,index) in introduceImg" :key="index" :src="item.path" alt="">
			</div> -->
		</div>
		<!-- 相关附件 -->
		<div class="detail-adjunct" v-if="adjunct == '1'">
			<h2 class="detail-common-head">相关附件</h2>
			<ul id="J_DownLoadFirst">没有相关下载附件</ul>
		</div>
	</div>
</template>

<script>
	export default {
		props: [ 'videoImg', 'adjunct','afficheCon','introduceImg' ],
		data() {
			return {
				
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	};
</script>

<style lang="less" scoped>
	.detail-main {
	    background: #fff;
	    padding-bottom: 52px;
		margin: 0 40px;
	    min-height: 580px;
		.pai-title {
		    position: relative;
		    text-align: center;
		    height: 60px;
		    padding-top: 15px;
		}
		.pai-remind-tip {
		    padding: 0 8px;
		    line-height: 24px;
		    font-size: 16px;
			p{
				font-size: 14px;
			}
		}
		.detail-common-text {
		    line-height: 26px;
		    margin-bottom: 20px;
		    text-indent: 2em;
		    word-wrap: break-word;
			p{
				font-size: 14px;
			}
		}
		// 拍卖公告
		.detail-adjunct{
			.detail-common-head {
			    height: 32px;
			    line-height: 32px;
			    margin: 40px 0 10px;
			    padding-left: 10px;
			    color: #333;
			    background: #f6f6f6;
			    font-weight: 700;
			    font-size: 14px;
			    border-left: solid 2px #d91615;
			}
			ul {
			    padding: 5px 0 10px 10px;
			}
		}
		// 标的介绍
		.video-img {
		    overflow: hidden;
		    margin-bottom: 20px;
		    text-align: center;
			.video_slide {
			    width: 900px;
			    margin: 10px auto;
			    display: none;
			}
			.sf-pic-slide{
				img {
					display: block;
					text-align: center;
					max-width: 100%;
					height: auto!important;
					margin: 10px auto;
				    cursor: -webkit-zoom-in;
				    cursor: zoom-in;
				}
			}
		}
	}
</style>
